<template>
  <div>
    <b-alert
      v-height-fade.appear
      :show="isPresent"
      dismissible
      class="mb-2"
      :variant="alert.type"
      @dismissed="alert.countdown = 0"
      @dismiss-count-down="countDownChanged"
    >
      <div class="alert-body">
        <span>{{ alert.message }}</span>
      </div>
    </b-alert>
  <component :is="'b-card'">
    <!-- Header: Personal Info -->
    <div class="d-flex">
      <feather-icon icon="UserIcon" size="19" class="text-primary" />
      <h4 class="mb-0 ml-50 text-primary"> {{ $t('Personal Information')}}</h4>
    </div>

    <!-- Form: Personal Info Form -->
    <validation-observer ref="simpleRules">
      <b-form class="mt-1">
        <b-row>
          <!-- Field: firstName -->
          <b-col cols="12" md="6" lg="4">
            <b-form-group :label="$t('First name')" label-for="firstName">
              <validation-provider #default="{ errors }" rules="required" name="First name">
                <b-form-input id="firstName" v-model="userData.firstName" :state="errors.length > 0 ? false:null" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <!-- Field: lastName -->
          <b-col cols="12" md="6" lg="4">
            <b-form-group :label="$t('Last Name')" label-for="lastName">
              <validation-provider #default="{ errors }" rules="required" name="Last Name">
                <b-form-input id="lastName" v-model="userData.lastName" :state="errors.length > 0 ? false:null" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <!-- Field: Telefon -->
          <b-col cols="12" md="6" lg="4">
            <b-form-group :label="$t('Phone Number')" label-for="phone">
              <validation-provider #default="{ errors }" name="Phone Number">
                <cleave id="phone" v-model="userData.phone" :state="errors.length > 0 ? false:null" placeholder="+ 99 (999) 999-9999" class="form-control" :raw="false" :options="options.phone"/>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <!-- Field: Active -->
          <b-col cols="12" md="6" lg="4">
            <b-form-group
              label=""
              label-for="contact-options"
              label-class="mb-1">
              <div class="demo-inline-spacing">
                <b-form-checkbox
                  v-model="userData.isActive"
                  class="custom-control-primary">
                  {{ $t('Active')}}
                </b-form-checkbox>
              </div>
            </b-form-group>
          </b-col>
        </b-row>

        <!-- Header: Personal Info -->
        <div class="d-flex mt-2">
          <feather-icon icon="SettingsIcon" size="19" class="text-primary" />
          <h4 class="mb-0 ml-50 text-primary">{{ $t('Account Information')}}</h4>
        </div>

        <!-- Form: User Info Form -->
        <b-row class="mt-1">

          <!-- Field: EPosta -->
          <b-col cols="12" md="6" lg="6">
            <b-form-group :label="$t('Email Address')" label-for="email">
              <validation-provider #default="{ errors }" rules="required|email" name="Email Address">
                <b-input-group>
                  <b-form-input id="email" v-model="userData.email" :state="errors.length > 0 ? false:null" />
                  <b-input-group-append>
                    <b-button :disabled="errors.length > 0" @click="checkAlan('email')" variant="outline-primary">
                      {{ $t('Check') }}
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <!-- Field: Kulanici Adi -->
          <b-col cols="12" md="6" lg="6" v-if="userData.userName">
            <b-form-group :label="$t('User Name')" label-for="userName">
                <b-input-group>
                  <b-form-input id="userName" v-model="userData.userName" readonly />
                </b-input-group>
            </b-form-group>
          </b-col>

          <!-- Field: Parola -->
          <b-col cols="12" md="6" lg="6" v-if="userData.id === 0">
            <b-form-group :label="$t('Password')" label-for="password">
              <validation-provider #default="{ errors }" rules="required" name="password" vid="password">
                <b-input-group>
                <b-form-input id="password" v-model="userData.password" :state="errors.length > 0 ? false:null" />
                <b-input-group-append>
                  <b-button @click="generate" variant="outline-primary">
                    {{ $t('Generate') }}
                  </b-button>
                </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row class="mt-2">
          <b-col>
            <save-button 
              :onClickSaveAndClose="saveKullanici.bind(this, true)"
              :onClickSave="saveKullanici.bind(this, false)"
              />
          </b-col>
          <!-- <b-col>
            <b-button
              variant="outline-primary"
              class="mt-2 float-right"
              @click="sendEmail"
              >
              <feather-icon
                icon="SendIcon"
                class="mr-50"
              />
            <span class="align-middle">{{ $t('Send Reminder Email')}}</span>
          </b-button>
            
          </b-col> -->
        </b-row>
      </b-form>
    </validation-observer>
  </component>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  required, email, min, max,
} from '@validations'

// Alert fade
import { heightFade } from '@core/directives/animations'
// Save Button
import SaveButton from '@/components/SaveButton.vue'

// Alert
import AlertService from '@/common/alert.service'

// Options
import StaticOptions from '@/common/options/StaticOptions';

// Toast
import ToastificationSuccess from '@core/components/toastification/ToastificationSuccess.vue'

import ApiService from '@/common/api.service';
import UserManageService from '@/common/custom-service/management/UserManageService';
// Selection
import Cleave from 'vue-cleave-component'

export default {
  components: {
    SaveButton,
    ValidationProvider,
    ValidationObserver,
    Cleave,
  },

  directives: {
    'height-fade': heightFade,
  },

  data() {
    return {
      required,
      min,
      max,
      email,
      isPresent: false,
      alert: {
        message: null,
        type: null,
        countdown: 5,
      },
      // Cleave options
      options: {    
        phone: {
          blocks: [0, 2, 3, 0, 3, 4],
          delimiter: ' ',
          delimiters: ['+', '(', ')', ' ', '-', '-'],    
          numericOnly: true,
          uppercase: false,
          lowercase: false,
        },
      },

      // User
      userData: {
        id: 0,
        email: null,
        pictureUrl: null,
        phone: null,
        firstName: null,
        lastName: null,
        userStatus: 3,
        userRole: 3,
        isActive: true,
      },
    }
  },

  methods: {
    fetchUser() {
      const paramId = this.$router.currentRoute.params.id;
      if (parseInt(paramId, 10) !== 0) {
        this.$store.dispatch('userManagement/fetchUser', { id: paramId })
          .then(response => {
            if (response.statusCode === 200) {
              this.userData = response.result;
            } else {
              AlertService.error(this, response.message)
            }
          })
      }
    },

    saveKullanici(isClose) {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.$store.dispatch('userManagement/saveUser', this.userData)
            .then(response => {
              if (response.statusCode === 200) {
                this.$toast({
                  component: ToastificationSuccess,
                  position: 'top-right',
                  props: { text: response.message },
                })
                if (isClose) {
                  this.$router.go(-1)
                } else {
                  this.$router.push({ name: 'manage-user-save', params: { id: response.result.id } });
                  this.fetchUser();
                }
              } else {
                AlertService.error(this, response.message)
              }
            });
        }
      })
    },

    generate: UserManageService.generate,

    sendEmail() {
      AlertService.confirmSuccess(this, () => {
        UserManageService.sendEmail(this.kullaniciData, this).then(response => {
          if (response.statusCode === 200) {
            AlertService.success(this, this.$t(response.message));
          } else {
            AlertService.error(this, this.$t(response.message));
          }
        })
      }, this.$t('Do you approve to send the reminder e-mail?'))
    },

    checkAlan(alanAdi) {
      let endPoint = 'manage/kullanicihesap/kullanici/kullanicikodu/check'
      const query = { id: this.kullaniciData.id };
      if (alanAdi === 'sEMail') {
        endPoint = 'manage/kullanicihesap/kullanici/email/check'
        query.sEMail = this.kullaniciData[alanAdi];
      } else {
        query.sKullaniciKodu = this.kullaniciData[alanAdi];
      }

      ApiService.post(endPoint, query, this).then(response => {
        if (response.result) {
          this.alert.type = 'warning'
          this.alert.message = response.message
          this.isPresent = true;
        } else {
          this.alert.type = 'success'
          this.alert.message = response.message
          this.isPresent = true;
        }
        setTimeout(() => {
            this.isPresent = false;
            this.alert.input = null
          }, 5000)
      })
    },

    countDownChanged(dismissCountDown) { 
      this.alert.countdown = dismissCountDown 
    },

    showAlert() { 
      this.alert.countdown = this.dismissSecs
    },
  },

  created() {
    this.fetchUser();
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
